.pagination {
    display: inline-block;
  }
  
  .pagination a {
    color: aqua;
    font-size: 30px;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
  }
  
  .pagination a.active {
    background-color: #4CAF50;
    color: white;
    border-radius: 10px;
  }
  
  .pagination a:hover:not(.active) {
    background-color: #61c0ae;
    border-radius: 10px;
    color: black;
  
  }