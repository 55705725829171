@import '~boxicons/css/boxicons.min.css';

.row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
  background-color: #06233a56;
  padding: 10px;
}

.movie {
  position:relative; /* Add position relative to the movie div */
  
  background-color: rgba(87, 84, 127, 0.594);
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 30px;
  font-size: 20px;
  text-align: center;
  height: 400px; /* Change to auto to allow content to determine height */
  width: auto;
}

.movie img {
  /* margin-top: 10%; */
  height: 90%;
  max-width: 100%; /* Ensure the image doesn't exceed its container's width */
  border: 10px solid yellow;
  border-radius: 10px;
}

.movie h3 {
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
  max-width: 100%; /* Ensure the title doesn't exceed the container's width */
}

.bx.bxs-heart {
  font-size: 30px;
  position: absolute; /* Position the heart icon relative to its container */
  top: 5px; /* Adjust the top position as needed */
  right: 5px; /* Adjust the right position as needed */
  font-size: 20px;
}

.liked {
  color: red;
}

@media screen and (max-width: 768px) {
  .row {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .movie {
    font-size: 16px;
  }

  .bx.bxs-heart {
    font-size: 20px; /* Adjust the font size for smaller screens if needed */
  }
}
